import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import useOrders from "../hooks/useOrders";
import useTenants from "../hooks/useTenants";
import { useCurrentOrderLoader } from "../hooks/useOrdersLoader";

import { getOrderStatusBySlug } from '../utils/tenantUtils';

import ActiveOrder from "./ActiveOrder";
import ClosedOrder from "./ClosedOrder";
import Loading from "./Loading";


const Order = () => {
    const { code } = useParams();

    const { tenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const [isLoading, setIsLoading] = useState(true);

    const loadCurrentOrder = useCurrentOrderLoader();

    const { orders } = useOrders();
    let currentOrder = orders?.find((order) => order.order_code === code);


    useEffect(() => {
        const getCurrentOrder = async () => {
            try {
                await loadCurrentOrder(currentOrder);
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }

        getCurrentOrder();
    }, [])

    return (
        <>
            {
                isLoading
                    ? <Loading />
                    : currentOrder.order_status === getOrderStatusBySlug('active', CURRENT_TENANT).id
                        ? <ActiveOrder code={code} />
                        : <ClosedOrder code={code} />
            }
        </>
    )

}

export default Order