import { useRef, useState, useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next';
// import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import axios from '../api/axios';

import ErrorToast from './ErrorToast';


const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/auth/registration/';

const Register = () => {
    const { t } = useTranslation();

    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const onVerify = useCallback((token) => {
        setToken(token)
    }, []);

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setRefreshReCaptcha(r => !r);

        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            await axios.post(REGISTER_URL,
                JSON.stringify(
                    {
                        username: user,
                        password1: pwd,
                        password2: matchPwd
                        // token,
                    }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setSuccess(true);

            //clear state and controlled inputs
            setUser('');
            setPwd('');
            setMatchPwd('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            // errRef.current.focus();
        }
    }

    return (
        <>
            {success ? (
                <section>
                    <h1>{t('Success') + "!"}</h1>
                    <p>
                        <Link to="/login">{t('Sign In')}</Link>
                    </p>
                </section>
            ) : (
                <section>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6 col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="my-4 d-flex flex-column align-items-center">
                                        {/* <p ref={errRef} className={errMsg ? "errmsg alert alert-danger text-center" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
                                        <ErrorToast errMsg={errMsg} />
                                        <h2>{t('Sign Up')}</h2>
                                        <p className="w-lg-50">{t('Create an account')}</p>
                                        <br />
                                        {/* <div className="bs-icon-xl bs-icon-circle bs-icon-primary bs-icon my-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-person">
                                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                                        </svg>
                                        </div> */}
                                        <form id="signupform" className="text-center" onSubmit={handleSubmit} data-bs-theme="light">
                                            <GoogleReCaptcha
                                                onVerify={onVerify}
                                                refreshReCaptcha={refreshReCaptcha}
                                            />
                                            {/* <label htmlFor="username">
                                                Username:
                                                <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                                                <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
                                            </label> */}
                                            <input
                                                type="text"
                                                id="username"
                                                ref={userRef}
                                                autoComplete="off"
                                                onChange={(e) => setUser(e.target.value)}
                                                value={user}
                                                required
                                                aria-invalid={validName ? "false" : "true"}
                                                aria-describedby="uidnote"
                                                onFocus={() => setUserFocus(true)}
                                                onBlur={() => setUserFocus(false)}
                                                placeholder={t('Username')}
                                                className='form-control mb-3'
                                            />
                                            {/* <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                4 to 24 characters.<br />
                                                Must begin with a letter.<br />
                                                Letters, numbers, underscores, hyphens allowed.
                                            </p> */}
                                            {
                                                userFocus && user && !validName
                                                    ? <p id="confirmnote" className="alert alert-danger text-center">
                                                        {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                                        {t('4 to 24 characters')}<br />
                                                        {t('Must begin with a letter')}<br />
                                                        {t('Letters, numbers, underscores, hyphens allowed')}
                                                    </p>
                                                    : null
                                            }


                                            {/* <label htmlFor="password">
                                                Password:
                                                <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                                                <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                                            </label> */}
                                            <input
                                                type="password"
                                                id="password"
                                                onChange={(e) => setPwd(e.target.value)}
                                                value={pwd}
                                                required
                                                aria-invalid={validPwd ? "false" : "true"}
                                                aria-describedby="pwdnote"
                                                onFocus={() => setPwdFocus(true)}
                                                onBlur={() => setPwdFocus(false)}
                                                placeholder={t('Password')}
                                                className='form-control mb-3'
                                            />
                                            {/* <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                8 to 24 characters.<br />
                                                Must include uppercase and lowercase letters, a number and a special character.<br />
                                                Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                            </p> */}
                                            {
                                                pwdFocus && !validPwd
                                                    ? <p id="confirmnote" className="alert alert-danger text-center">
                                                        {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                                        {t('8 to 24 characters')}<br />
                                                        {t('Must include uppercase and lowercase letters, a number and a special character')}<br />
                                                        {t('Allowed special characters') + ": "} <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                                    </p>
                                                    : null
                                            }


                                            {/* <label htmlFor="confirm_pwd">
                                                Confirm Password:
                                                <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                                                <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                                            </label> */}
                                            <input
                                                type="password"
                                                id="confirm_pwd"
                                                onChange={(e) => setMatchPwd(e.target.value)}
                                                value={matchPwd}
                                                required
                                                aria-invalid={validMatch ? "false" : "true"}
                                                aria-describedby="confirmnote"
                                                onFocus={() => setMatchFocus(true)}
                                                onBlur={() => setMatchFocus(false)}
                                                placeholder={t('Repeat your password')}
                                                className='form-control mb-3'
                                            />
                                            {/* <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                Must match the first password input field.
                                            </p> */}
                                            {
                                                matchFocus && !validMatch
                                                    ? <p id="confirmnote" className="alert alert-danger text-center">
                                                        {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                                        {t('Must match the first password input field')}
                                                    </p>
                                                    : null
                                            }

                                            <button className="btn btn-primary shadow d-block w-100 mb-3" disabled={!validName || !validPwd || !validMatch ? true : false}>{t('Sign Up')}</button>
                                        </form>
                                        <p className="text-muted mb-0">
                                            <span>{t('Already have an account') + "?"}</span>
                                        </p>
                                        <Link to="/login">{t('Login')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
            )}
        </>
    )
}

export default Register
