import { Link } from "react-router-dom";

import "./NavbarLogo.css";

const NavbarLogo = ({ brandName }) => {
    return (
        <Link className="navbar-brand d-flex align-items-center" to="/">
            <div className="bs-icon-sm bs-icon-rounded bs-icon-primary bs-icon shadow mx-2">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em"
                    fill="currentColor">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M18.06 22.99h1.66c.84 0 1.53-.64 1.63-1.46L23 5.05h-5V1h-1.97v4.05h-4.97l.3 2.34c1.71.47 3.31 1.32 4.27 2.26 1.44 1.42 2.43 2.89 2.43 5.29v8.05zM1 21.99V21h15.03v.99c0 .55-.45 1-1.01 1H2.01c-.56 0-1.01-.45-1.01-1zm15.03-7c0-8-15.03-8-15.03 0h15.03zM1.02 17h15v2h-15z"></path>
                </svg>
            </div>
            { brandName }
        </Link>
    )
}

export default NavbarLogo