const DEFAULT_ROLES = {
    owners: 'owners',
    managers: 'managers',
    waiters: 'waiters'
}

const BASE_URL = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8000';

const TENANT_URL = '/v1/tenant/';

const BRAND_NAME = "Trace Plates";

export { BASE_URL, BRAND_NAME, DEFAULT_ROLES, TENANT_URL }