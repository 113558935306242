import { createContext, useState } from "react";


const TenantsContext = createContext({});

export const TenantsProvider = ({ children }) => {
    const [tenants, setTenants] = useState({});

    return (
        <TenantsContext.Provider value={{ tenants, setTenants }}>
            {children}
        </TenantsContext.Provider>
    )
}

export default TenantsContext;