import { Routes, Route } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"

import Register from './components/Register';
import Login from './components/Login';
import Layout from './components/Layout';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import RequireRoles from './components/RequireRoles';
import PersistLogin from './components/PersistLogin';
import Menu from './components/Menu';
import Orders from './components/Orders';
import Order from './components/Order';
import LoadTenants from './components/LoadTenants';
import LoadOrders from './components/LoadOrders';
import Processes from './components/Processes';
import OrderHistory from './components/OrderHistory';
import RedirectFromRoot from './components/RedirectFromRoot';
import Logout from './components/Logout';
import { DEFAULT_ROLES } from "./constants";


const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="logout" element={<Logout />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<LoadTenants />}>
            <Route path="/" element={<RedirectFromRoot />} />
            
            <Route element={<LoadOrders />}>
              <Route element={<RequireRoles allowedRoles={[DEFAULT_ROLES.owners, DEFAULT_ROLES.managers, DEFAULT_ROLES.waiters]} />}>
                <Route path="menu" element={<Menu />} />
                <Route path="menu/for-order/:code" element={<Menu />} />
                <Route path="orders" element={<Orders />} />
                <Route path="order/:code" element={<Order />} />
              </Route>
              <Route element={<RequireRoles prohibitedRoles={[DEFAULT_ROLES.waiters]} />}>
                <Route path="process/:code" element={<Processes />} />
              </Route>
              <Route element={<RequireRoles allowedRoles={[DEFAULT_ROLES.owners]} />}>
                <Route path="history/:code" element={<OrderHistory />} />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App