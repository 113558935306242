const ContentHeader = ({ text, subtext }) => {
    return (
        <section>
            <div className="row mb-5">
                <div className="col-md-8 col-xl-6 text-center mx-auto">
                    <h2>{text}</h2>
                    <p className="w-lg-50">{subtext}</p>
                </div>
            </div>
        </section>
    );
}

export default ContentHeader