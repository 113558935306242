import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import ContentHeader from './ContentHeader';

import useAuth from "../hooks/useAuth";
import useTenants from "../hooks/useTenants";
import useOrders from "../hooks/useOrders";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useOrdersLoader } from "../hooks/useOrdersLoader";

import apiDateConverter from '../utils/apiDateConverter';
import { getOrderStatus } from '../utils/tenantUtils';

import { isAllowed, getCurrentRoles } from "../utils/authUtils";
import { DEFAULT_ROLES, TENANT_URL } from "../constants";


const Orders = () => {
    const { t, i18n } = useTranslation();

    const { auth } = useAuth();

    const { tenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const { orders, setOrders } = useOrders();

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const currentRoles = getCurrentRoles(auth, CURRENT_TENANT);

    const loadOrders = useOrdersLoader();


    const refreshOrders = async () => {
        try {
            await loadOrders();
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            refreshOrders();
        }, 15 * 1000);
        refreshOrders();
        return () => clearInterval(interval);
    }, []);

    const DeleteOrderButton = ({ order }) => {

        const deleteOrder = async () => {
            try {
                await axiosPrivate.delete(TENANT_URL + CURRENT_TENANT.id + '/order/' + order.id + '/');
                setOrders(orders.filter((item) => item.id !== order.id));
            } catch (err) {
                console.error(err);
            }
        }

        if (isAllowed(currentRoles, [DEFAULT_ROLES.owners])) {
            return (
                <button className="btn btn-outline-danger" onClick={deleteOrder}>
                    <svg className="bi bi-trash3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"></path>
                    </svg>
                </button>
            )
        } else {
            return <></>
        }
    }

    const ViewOrderButton = ({ order }) => {
        const viewOrder = async () => {
            navigate('/order/' + order.order_code);
        }
        return (
            <button className="btn btn-outline-primary" onClick={viewOrder}>{t('View')}</button>
        )
    }

    const ViewOrderHistoryButton = ({ order }) => {
        const viewOrderHistory = async () => {
            navigate('/history/' + order.order_code);
        }
        if (isAllowed(currentRoles, [DEFAULT_ROLES.owners])) {
            return (
                <button className="btn btn-outline-secondary" onClick={viewOrderHistory}>{t('History')}</button>
            )
        }
        else {
            return <></>
        }
    }

    const Order = ({ order }) => {
        const orderStatus = getOrderStatus(order, CURRENT_TENANT)
        if (order) {
            return (
                <tr>
                    <td className="align-middle">{order.order_code}</td>
                    <td className="align-middle">{apiDateConverter(order.created)}</td>
                    <td className="align-middle">{order.table}</td>
                    <td className="align-middle">{orderStatus['name_' + i18n.resolvedLanguage] || orderStatus.name_en}</td>
                    <td className="align-middle">{order.order_items_count}</td>
                    <td className="align-middle">{order.total}</td>
                    <td className="align-middle text-end">
                        <div className="btn-group" role="group">
                            <ViewOrderButton order={order} />
                            <ViewOrderHistoryButton order={order} />
                            <DeleteOrderButton order={order} />
                        </div>
                    </td>
                </tr>
            )
        } else {
            return <tr></tr>
        }
    }

    const OrderList = ({ orders }) => {
        const sorted_orders = orders.sort(function (a, b) {
            return new Date(b.created) - new Date(a.created);
        });

        if (sorted_orders) {
            const allowed = isAllowed(currentRoles, [DEFAULT_ROLES.owners, DEFAULT_ROLES.managers])
            return (
                <tbody>
                    {
                        sorted_orders.map(
                            (order) => (
                                allowed || getOrderStatus(order, CURRENT_TENANT).slug === "active"
                                    ? <Order key={order?.order_code} order={order} />
                                    : null
                            )
                        )
                    }
                </tbody>
            )
        } else {
            return (
                <tbody>
                    <tr>
                        <td colSpan="7">{t('No orders found for this order')}</td>
                    </tr>
                </tbody>
            )
        }
    }

    const NewOrderButton = () => {
        return (
            <button className="btn btn-primary text-end shadow mt-2" onClick={() => navigate('/menu/')}>{t('New order')}</button>
        )
    }

    const Statistics = ({ tenant }) => {
        if (isAllowed(currentRoles, [DEFAULT_ROLES.owners])) {
            return (
                <div className="row">
                    <div className="col pt-4">
                        <h5 className="fw-bold">{t('Statistics') + ':'}</h5>
                        <br />
                        <p>{t('All orders') + ": " + tenant.all_orders_total + " ILS"}</p>
                        <p>{t('All paid orders') + ": " + tenant.paid_orders_total + " ILS"}</p>
                        {/* <br/>
                        <p>Orders today { all_orders_total_for_day } ILS</p>
                        <p>Paid orders today { paid_orders_total_for_day } ILS</p>  */}
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            <ContentHeader text={t('Orders')} subtext={t('Select an order to view or edit')} />
            <div className="row">
                <div className="col">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{t('Order')}</th>
                                    <th>{t('Created at')}</th>
                                    <th>{t('Table')}</th>
                                    <th>{t('Status')}</th>
                                    <th>{t('Items')}</th>
                                    <th>{t('Totals')}</th>
                                    <th className="text-end">{t('Actions')}</th>
                                </tr>
                            </thead>
                            <OrderList orders={orders} />
                        </table>
                        {/* {% include "partials/paginator.html" %} */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col text-end">
                    <NewOrderButton />
                </div>
            </div>
            <Statistics tenant={CURRENT_TENANT} />
        </>
    )
}

export default Orders