import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useOrdersLoader } from "../hooks/useOrdersLoader";

import Loading from "./Loading";


const LoadOrders = () => {
    const [isLoading, setIsLoading] = useState(true);

    const loadOrders = useOrdersLoader();

    useEffect(() => {
        const getOrders = async () => {
            try {
                await loadOrders();
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }

        // Get orders from the server
        getOrders();
    }, [])

    return (
        <>
            {
                isLoading
                    ? <Loading />
                    : <Outlet />
            }
        </>
    )
}

export default LoadOrders