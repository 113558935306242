import { useRef, useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import axios from '../api/axios';

import useAuth from '../hooks/useAuth';
import useInput from '../hooks/useInput';
import useToggle from '../hooks/useToggle';

import { getAuth } from "../utils/authUtils";

import ErrorToast from './ErrorToast';


const LOGIN_URL = '/auth/login/';

const Login = () => {
    const { t } = useTranslation();

    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const onVerify = useCallback((token) => {
        setToken(token)
    }, []);

    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, resetUser, userAttribs] = useInput('user', '');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', true);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setRefreshReCaptcha(r => !r);

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify(
                    {
                        username: user,
                        password: pwd
                        // token,
                    }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    withCredentials: true
                }
            );

            setAuth(await getAuth(response));

            resetUser();
            setPwd('');
            navigate(from, { replace: true });

        } catch (err) {
            // setRefreshReCaptcha(!refreshReCaptcha);
            // console.error("Login: ", JSON.stringify(err));
            if (!err?.response) {
                setErrMsg('No Server Response');
                // } else if (err.response?.status === 400) {
                //     setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            // errRef.current.focus();
        }
    }

    return (
        <section>
            <div className="row d-flex justify-content-center">
                <div className="col-md-6 col-xl-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="my-4 d-flex flex-column align-items-center">
                                {/* <p className={errMsg ? "errmsg alert alert-danger text-center" : "offscreen"} ref={errRef} aria-live="assertive">{errMsg}</p> */}
                                <ErrorToast errMsg={errMsg} />
                                <h2>{t('Login')}</h2>
                                <p className="w-lg-50">{t('Sign in to your account')}</p>
                                <br />
                                {/* <div className="bs-icon-xl bs-icon-circle bs-icon-primary bs-icon my-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-person">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                                    </svg>
                                </div> */}
                                <form id="loginform" className="text-center" onSubmit={handleSubmit} data-bs-theme="light">
                                    {/* <label htmlFor="username">Username:</label> */}
                                    <GoogleReCaptcha
                                        onVerify={onVerify}
                                        refreshReCaptcha={refreshReCaptcha}
                                    />
                                    <input
                                        type="text"
                                        id="username"
                                        ref={userRef}
                                        autoComplete="off"
                                        {...userAttribs}
                                        required
                                        placeholder={t('Username')}
                                        className='form-control mb-3'
                                    />
                                    {/* <label htmlFor="password">Password:</label> */}
                                    <input
                                        type="password"
                                        id="password"
                                        onChange={(e) => setPwd(e.target.value)}
                                        value={pwd}
                                        required
                                        placeholder={t('Password')}
                                        className='form-control mb-3'
                                    />
                                    <button className="btn btn-primary shadow d-block w-100 mb-3">{t('Login')}</button>
                                    <div className="persistCheck form-check form-switch mb-3 mx-4">
                                        <input
                                            type="checkbox"
                                            id="persist"
                                            onChange={toggleCheck}
                                            checked={check}
                                            className='form-check-input'
                                        />
                                        <label htmlFor="persist" className="form-check-label float-end">{t('Trust This Device')}</label>
                                    </div>
                                    {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
                                        <GoogleReCaptcha
                                            className="google-recaptcha-custom-class"
                                            onVerify={setTokenFunc}
                                            refreshReCaptcha={refreshReCaptcha}
                                        />
                                    </GoogleReCaptchaProvider> */}
                                </form>
                                <p className="text-muted mb-0">
                                    <span>{t("Don't have an account") + "?"}</span>
                                </p>
                                <Link to="/register">{t('Register')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Login
