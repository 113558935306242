import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

import ContentHeader from './ContentHeader';

import useAuth from "../hooks/useAuth";
import useOrders from "../hooks/useOrders";
import useTenants from "../hooks/useTenants";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useCurrentOrderLoader } from "../hooks/useOrdersLoader";

import {
    getMenuItem,
    getProcess,
    getOrderStatusBySlug,
    getOrderItemStatus,
    getOrderItemStatusBySlug,
} from '../utils/tenantUtils';
import { isAllowed, getCurrentRoles } from "../utils/authUtils";
import { DEFAULT_ROLES, TENANT_URL } from "../constants";


const ActiveOrder = ({ code }) => {
    const { t, i18n } = useTranslation();

    const { auth } = useAuth();

    const { tenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const currentRoles = getCurrentRoles(auth, CURRENT_TENANT);

    const { orders } = useOrders();

    const [ currentOrder, setCurrentOrder ] = useState(orders?.find((order) => order.order_code === code));

    const loadCurrentOrder = useCurrentOrderLoader();
    

    useEffect(() => {
        const updateCurrentOrder = async () => {
            try {
                const updatedOrder = await loadCurrentOrder(currentOrder);
                if ( !isEqual(currentOrder, updatedOrder)) {
                    setCurrentOrder(updatedOrder);
                }
            } catch (err) {
                console.error(err);
            } 
        }
        updateCurrentOrder();
    }, [currentOrder])

    const RemoveOrderItemButton = ({ orderItem }) => {
        const removeOrderItem = async () => {
            try {
                await axiosPrivate.delete(TENANT_URL + CURRENT_TENANT.id + '/order/' + orderItem.order + '/item/' + orderItem.id + '/');
                const oder_items = currentOrder.oder_items.filter((item) => item.id !== orderItem.id);
                setCurrentOrder({ ...currentOrder, oder_items: oder_items });
            } catch (err) {
                console.error(err);
            }
        }

        if (isAllowed(currentRoles, [DEFAULT_ROLES.managers, DEFAULT_ROLES.owners]) ) {
            const disabled = (orderItem.order_item_status !== getOrderItemStatusBySlug('pending', CURRENT_TENANT).id)
            return (
                <button className="btn btn-outline-primary btn-lg px-3" disabled={disabled} onClick={removeOrderItem}>-</button>
            )
        }
        else {
            return <></>
        }
    }

    const AddSameOrderItemButton = ({ orderItem, menuItem }) => {
        const addSameOrderItem = async () => {
            try {
                // Create order item
                const response = await axiosPrivate.post(TENANT_URL + CURRENT_TENANT.id + '/order/' + orderItem.order + '/item/',
                    JSON.stringify({
                        tenant: CURRENT_TENANT.id,
                        order: orderItem.order,
                        menu_item: menuItem.id,
                        order_item_status: getOrderItemStatusBySlug('pending', CURRENT_TENANT).id,
                    })
                );
                const responseData = response?.data;
                const oder_items = [...currentOrder.oder_items, responseData];
                setCurrentOrder({ ...currentOrder, oder_items: oder_items });
            } catch (err) {
                console.error(err);
            }
        }

        return (
            <button className="btn btn-outline-primary btn-lg px-3" onClick={addSameOrderItem}>+</button>
        )
    }

    const OrderItem = ({ orderItem }) => {
        const menuItem = getMenuItem(orderItem, CURRENT_TENANT)
        const orderItemStatus = getOrderItemStatus(orderItem, CURRENT_TENANT)
        const processName = getProcess(menuItem, CURRENT_TENANT)

        if (orderItem) {
            return (
                <tr>
                    <td className="align-middle">{menuItem.name}</td>
                    <td className="align-middle">{menuItem.price} ILS</td>
                    <td className="align-middle">{orderItemStatus['name_' + i18n.resolvedLanguage] || orderItemStatus.name_en}</td>
                    <td className="align-middle">{processName['name_' + i18n.resolvedLanguage] || processName.name_en}</td>
                    <td className="align-middle d-flex justify-content-end">
                        <div className="d-inline-flex input-group justify-content-end" name="quantity" style={{ wight: "6em" }}>
                            <RemoveOrderItemButton orderItem={orderItem} />
                            <AddSameOrderItemButton orderItem={orderItem} menuItem={menuItem} />
                        </div>
                    </td>
                </tr>
            )
        } else {
            return <tr></tr>
        }
    }

    const OrderItemList = ({ orderItems }) => {
        if (orderItems) {
            return (
                <tbody>
                    {orderItems.map((orderItem) => (<OrderItem key={orderItem.id} orderItem={orderItem} />))}
                </tbody>
            )
        } else {
            return <tbody></tbody>
        }
    }

    const TableSelector = ({ tableNumber, maxTables }) => {
        const selectTable = async (value) => {
            try {
                await axiosPrivate.patch(TENANT_URL + CURRENT_TENANT.id + '/order/' + currentOrder.id + '/',
                    JSON.stringify({
                        table: value
                    })
                );
                setCurrentOrder({ ...currentOrder, table: value });
            } catch (err) {
                console.error(err);
            }
        }

        const GetTablesOptions = ({ tables }) => {
            const tablesArray = [...Array(tables).keys()].map(i => i + 1);
            return (
                <>
                    {tablesArray.map((table) => <option key={table} value={table}>{t("Table") + ": " + table}</option>)}
                </>
            )
        }

        return (
            <select className="form-select" value={tableNumber} onChange={(e) => selectTable(e.target.value)}>
                <GetTablesOptions tables={maxTables} />
            </select>
        )
    }

    const AddNewOrderItemButton = ({ orderCode }) => {
        return (
            <button className="btn btn-primary shadow" onClick={() => navigate('/menu/for-order/' + orderCode)}>{t('Add new item')}</button>
        )
    }

    const CloseOrderButton = ({ order }) => {
        const closeOrder = async () => {
            try {
                // Close order
                await axiosPrivate.patch(TENANT_URL + CURRENT_TENANT.id + '/order/' + order.id + '/',
                    JSON.stringify({
                        order_status: getOrderStatusBySlug('closed', CURRENT_TENANT).id
                    })
                );
                setCurrentOrder({ ...currentOrder, order_status: getOrderStatusBySlug('closed', CURRENT_TENANT).id });
                navigate('/orders');
            } catch (err) {
                console.error(err);
            }
        }

        if (isAllowed(currentRoles, [DEFAULT_ROLES.managers, DEFAULT_ROLES.owners])) {
            if (order.ready_to_close) {
                return (
                    <button className="btn btn-primary text-end shadow mt-2" onClick={closeOrder}>{t('Close Order')}</button>
                )
            } else {
                return (
                    <button className="btn btn-primary text-end shadow mt-2" disabled>{t('Close Order')}</button>
                )
            }
        } else {
            return <></>
        }
    }

    const CancelOrderButton = ({ order }) => {
        const cancelOrder = async () => {
            try {
                // Cancel order
                await axiosPrivate.patch(TENANT_URL + CURRENT_TENANT.id + '/order/' + order.id + '/',
                    JSON.stringify({
                        order_status: getOrderStatusBySlug('cancelled', CURRENT_TENANT).id,
                    })
                );
                for (let orderItem of order.oder_items) {
                    await axiosPrivate.patch(TENANT_URL + CURRENT_TENANT.id + '/order/' + order.id + '/item/' + orderItem.id + '/',
                        JSON.stringify({
                            is_active: false
                        })
                    );
                }
                setCurrentOrder({ ...currentOrder, order_status: getOrderStatusBySlug('cancelled', CURRENT_TENANT).id });
                navigate('/orders');
            } catch (err) {
                console.error(err);
            }
        }
        if (isAllowed(currentRoles, [DEFAULT_ROLES.managers, DEFAULT_ROLES.owners])) {
            return (
                <button className="btn text-end mt-2 mx-2 btn-outline-primary" onClick={cancelOrder}>{t('Cancel order')}</button>
            )
        } else {
            return <></>
        }
    }

    const Result = ({ order }) => {
        return (
            <>
                <ContentHeader text={t('Order') + ": " + order.order_code} subtext={t('Order editor')} />
                <div className="row">
                    <div className="col">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>{t('Item')}</th>
                                        <th>{t('Price')}</th>
                                        <th>{t('Status')}</th>
                                        <th>{t('Category')}</th>
                                        <th className="text-end">{t('Actions')}</th>
                                    </tr>
                                </thead>
                                <OrderItemList orderItems={order.oder_items} />
                            </table>
                            {/* {% include "partials/paginator.html" %} */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-auto">
                        <TableSelector tableNumber={order.table} maxTables={CURRENT_TENANT.tables} />
                    </div>
                    <div className="col text-end">
                        <AddNewOrderItemButton orderCode={order.order_code} />
                    </div>
                </div>
                <div className="row">
                    <div className="col text-end pt-4 ">
                        <h5 className="fw-bold">{t('Subtotal') + ": " + order.subtotal} ILS</h5>
                        <h4 className="fw-bold">{t('Total') + ": " + order.total} ILS</h4>
                        <CancelOrderButton order={order} />
                        <CloseOrderButton order={order} />
                    </div>
                </div>
            </>
        )
    }

    return (
        <Result order={currentOrder} />
    )

}

export default ActiveOrder