import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import ContentHeader from './ContentHeader';
import Loading from "./Loading";

import useOrders from "../hooks/useOrders";
import useTenants from "../hooks/useTenants";
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import { getMenuItem, getProcessBySlug, getOrderItemStatusBySlug } from '../utils/tenantUtils';
import { TENANT_URL } from "../constants";


const Processes = () => {
    const { t, i18n } = useTranslation();

    const { code } = useParams();

    const { tenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const { orders } = useOrders();

    const [process, setProcess] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const axiosPrivate = useAxiosPrivate();


    const getOrderItems = async () => {
        try {
            let currentProcess = getProcessBySlug(code, CURRENT_TENANT);
            const response = await axiosPrivate.get(TENANT_URL + CURRENT_TENANT.id + '/process/' + currentProcess.id + '/item/');
            const responseData = response?.data;
            setProcess({ ...currentProcess, order_items: responseData.filter((item) => item.order_item_status === getOrderItemStatusBySlug('pending', CURRENT_TENANT).id) });
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getOrderItems();
        }, 5 * 1000);
        getOrderItems();
        return () => clearInterval(interval);
    }, [code]);

    const ReadyButton = ({ orderItem }) => {
        const ready = async () => {
            try {
                await axiosPrivate.patch(TENANT_URL + CURRENT_TENANT.id + '/order/' + orderItem.order + '/item/' + orderItem.id + '/',
                    JSON.stringify({
                        order_item_status: getOrderItemStatusBySlug('ready', CURRENT_TENANT).id,
                    })
                );
                getOrderItems();
            } catch (err) {
                console.error(err);
            }
        }

        return (
            <button className="btn btn-success btn-lg" onClick={ready}>{t('Ready')}</button>
        )
    }

    const OrderItem = ({ orderItem }) => {
        const menuItem = getMenuItem(orderItem, CURRENT_TENANT);
        const orderCode = orders.find((item) => item.id === orderItem.order)?.order_code
        return (
            <tr>
                <td className="align-middle">{menuItem?.name}</td>
                <td className="align-middle">{orderCode}</td>
                <td className="align-middle text-end">
                    <ReadyButton orderItem={orderItem} />
                </td>
            </tr>
        )
    }

    const OrderItemList = ({ orderItems }) => {
        return (
            <tbody>
                {
                orderItems.map((orderItem) => (
                    orderItem.is_active 
                        ? <OrderItem key={orderItem.id} orderItem={orderItem} />
                        : null
                    ))
                }
            </tbody>
        )
    }

    const Result = ({ process }) => {
        return (
            <>
                <ContentHeader text={process['name_' + i18n.resolvedLanguage] || process.name_en} subtext={t('Mark the items ready for pickup')} />
                <div className="row">
                    <div className="col">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>{t('Item')}</th>
                                        <th>{t('Order')}</th>
                                        <th className="text-end">{t('Actions')}</th>
                                    </tr>
                                </thead>
                                <OrderItemList orderItems={process.order_items} />
                            </table>
                            {/* {% include "partials/paginator.html" %} */}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {
                isLoading
                    ? <Loading />
                    : <Result process={process} />
            }
        </>
    )
}

export default Processes