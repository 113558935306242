const getMenuItem = (orderItem, tenant) => tenant.menu?.find((menuItem) => menuItem.id === orderItem.menu_item);

const getProcess = (menuItem, tenant) => tenant.processes?.find((process) => process.id === menuItem.category);
const getProcessBySlug = (slug, tenant) => tenant.processes?.find((process) => process.slug === slug);
    
const getOrderStatus = (order, tenant) => tenant.statuses?.order?.find((status) => status.id === order.order_status);
const getOrderStatusBySlug = (slug, tenant) => tenant.statuses?.order?.find((status) => status.slug === slug);

const getOrderItemStatus = (orderItem, tenant) => tenant.statuses?.order_item?.find((status) => status.id === orderItem.order_item_status);
const getOrderItemStatusBySlug = (slug, tenant) => tenant.statuses?.order_item?.find((status) => status.slug === slug);

export {
    getMenuItem,
    getProcess,
    getProcessBySlug,
    getOrderStatus,
    getOrderStatusBySlug,
    getOrderItemStatus,
    getOrderItemStatusBySlug,
}
