import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import ContentHeader from './ContentHeader';
import Loading from "./Loading";

import useOrders from "../hooks/useOrders";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useTenants from "../hooks/useTenants";

import apiDateConverter from '../utils/apiDateConverter';
import uniqByMenuItem from '../utils/uniqByMenuItem';
import { getMenuItem, getOrderStatus } from '../utils/tenantUtils';
import { TENANT_URL } from "../constants";


const OrderHistory = () => {
    const { t } = useTranslation();

    const { code } = useParams();

    const { tenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const { orders } = useOrders();

    const [orderHistory, setOrderHistory] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const axiosPrivate = useAxiosPrivate();

    const getOrderHistory = async () => {
        try {
            const currentOrder = orders?.find((item) => item.order_code === code);
            const response = await axiosPrivate.get(TENANT_URL + CURRENT_TENANT.id + '/order/' + currentOrder.id + '/history/');
            const responseData = response?.data;
            setOrderHistory(responseData);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // Get order history from the server
        getOrderHistory();
    }, [code])


    const OderHistoryItemOrderItems = ({ orderItems }) => {
        const result = uniqByMenuItem(orderItems).map((orderItem) => (orderItem.quantity + ' x ' + getMenuItem(orderItem, CURRENT_TENANT).name)).join(', ')

        if (orderItems.length > 0) {
            return (
                <td className="text-end">{result}</td>
            )
        } else {
            return (
                <td className="text-end">-</td>
            )
        }
    }

    const OderHistoryItem = ({ orderHistoryItem }) => {
        return (
            <tr>
                <td>{apiDateConverter(orderHistoryItem.history_date)}</td>
                <td>{orderHistoryItem.user_name}</td>
                <td>{orderHistoryItem.history_type}</td>
                <td>{orderHistoryItem.table}</td>
                <td>{getOrderStatus(orderHistoryItem, CURRENT_TENANT).name}</td>
                <OderHistoryItemOrderItems orderItems={orderHistoryItem.order_items} />
            </tr>
        )
    }

    const OderHistoryList = ({ orderHistory }) => {
        if (orderHistory.length > 0) {
            return (
                <tbody>
                    {orderHistory.map((item) => (<OderHistoryItem orderHistoryItem={item} key={item.history_id} />))}
                </tbody>
            )
        } else {
            return (
                <tbody>
                    <tr>
                        <td colSpan="6">No history found for this order</td>
                    </tr>
                </tbody>
            )
        }
    }

    const Result = ({ orderHistory }) => {
        return (
            <>
                <ContentHeader text={"Order: " + code} subtext="Order history" />
                <div className="row">
                    <div className="col">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>{t('Date')}</th>
                                        <th>{t('User')}</th>
                                        <th>{t('Type')}</th>
                                        <th>{t('Table')}</th>
                                        <th>{t('Order Status')}</th>
                                        <th className="text-end">{t('Order items')}</th>
                                    </tr>
                                </thead>
                                <OderHistoryList orderHistory={orderHistory} />
                            </table>
                            {/* {% include "partials/paginator.html" %} */}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {
                isLoading
                    ? <Loading />
                    : <Result orderHistory={orderHistory} />
            }
        </>
    )
}

export default OrderHistory