import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import useLocalStorage from "../hooks/useLocalStorage";

import { BRAND_NAME } from "../constants";

import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout = () => {

    const { i18n } = useTranslation();
    const [localLang] = useLocalStorage('lang', 'en');

    useEffect(() => {
        if (localLang !== i18n.language) {
            i18n.changeLanguage(localLang);
        }
    }, [localLang]);

    return (
        <div className="d-flex flex-column min-vh-100">
            <Navbar brandName={BRAND_NAME} />
            <section className="position-relative py-4">
                <div className="container">
                    <main className="App">
                        <Outlet />
                    </main>
                </div>
            </section>
            <Footer brandName={BRAND_NAME} />
        </div>
    )
}

export default Layout
