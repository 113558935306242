import { useParams, useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { useTranslation } from 'react-i18next';

import ContentHeader from './ContentHeader';

import useAuth from "../hooks/useAuth";
import useTenants from "../hooks/useTenants";
import useOrders from "../hooks/useOrders";
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import {
    getProcess,
    getProcessBySlug,
    getOrderStatusBySlug,
    getOrderItemStatusBySlug,
} from '../utils/tenantUtils';
import { isAllowed, isProhibited, getCurrentRoles } from "../utils/authUtils";
import { DEFAULT_ROLES, TENANT_URL } from "../constants";

const ALLOWED_EXTENSIONS = ["csv"];

const Menu = () => {
    const { t, i18n } = useTranslation();

    const { auth } = useAuth();
    const { code } = useParams();

    const { tenants, setTenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const { orders, setOrders } = useOrders();

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const currentRoles = getCurrentRoles(auth, CURRENT_TENANT);

    const OrderButton = ({ menuItem, orderCode }) => {
        const createNewOrder = async () => {
            try {
                // Create a new order
                const response = await axiosPrivate.post(TENANT_URL + CURRENT_TENANT.id + '/order/',
                    JSON.stringify({
                        tenant: CURRENT_TENANT.id,
                        order_status: getOrderStatusBySlug('active', CURRENT_TENANT).id,
                    }),
                );
                const responseData = response?.data;
                await addItemToOrder(responseData, menuItem);
                setOrders([...orders, responseData]);
                navigate('/order/' + responseData.order_code);

            } catch (err) {
                console.error(err);
            }
        }

        const addItemToOrder = async (order, menuItem) => {
            try {
                // Create order item
                await axiosPrivate.post(TENANT_URL + CURRENT_TENANT.id + '/order/' + order.id + '/item/',
                    JSON.stringify({
                        tenant: CURRENT_TENANT.id,
                        order: order.id,
                        menu_item: menuItem.id,
                        order_item_status: getOrderItemStatusBySlug('pending', CURRENT_TENANT).id,
                    })
                );
            } catch (err) {
                console.error(err);
            }
        }

        if (menuItem.is_active) {
            if (orderCode) {
                const handleAddItemToOrder = async () => {
                    await addItemToOrder(orders?.find((item) => item.order_code === orderCode), menuItem)
                    navigate('/order/' + orderCode);
                }

                return (
                    <button className="btn btn-outline-primary" onClick={handleAddItemToOrder}>{t('Add to the Order') + ": " + orderCode}</button>
                )
            } else {
                return (
                    <button className="btn btn-outline-primary" onClick={createNewOrder}>{t('Add to a new Order')}</button>
                )
            }
        }
    }

    const ManageMenuItemButton = ({ menuItem }) => {
        const deactivateMenuItem = async () => {
            try {
                await axiosPrivate.patch(TENANT_URL + CURRENT_TENANT.id + '/menu/' + menuItem.id + '/',
                    JSON.stringify({
                        is_active: false
                    })
                );

                CURRENT_TENANT.menu.find((item) => item.id === menuItem.id).is_active = false;
                setTenants({ ...tenants, current: CURRENT_TENANT });
            } catch (err) {
                console.error(err);
            }
        }

        const activateMenuItem = async () => {
            try {
                await axiosPrivate.patch(TENANT_URL + CURRENT_TENANT.id + '/menu/' + menuItem.id + '/',
                    JSON.stringify({
                        is_active: true
                    })
                );

                CURRENT_TENANT.menu.find((item) => item.id === menuItem.id).is_active = true;
                setTenants({ ...tenants, current: CURRENT_TENANT });
            } catch (err) {
                console.error(err);
            }
        }

        if (isAllowed(currentRoles, [DEFAULT_ROLES.owners])) {
            if (menuItem.is_active) {
                return (
                    <button className="btn btn-outline-danger" onClick={deactivateMenuItem}>{t('Deactivate')}</button>
                )
            } else {
                return (
                    <button className="btn btn-outline-success" onClick={activateMenuItem}>{t('Activate')}</button>
                )
            }
        } else {
            return <></>
        }
    }

    const MenuItem = ({ menuItem, orderCode }) => {
        const processName = getProcess(menuItem, CURRENT_TENANT)
        if (menuItem) {
            return (
                <tr>
                    <td className="align-middle">{menuItem.name}</td>
                    <td className="align-middle">{processName['name_' + i18n.resolvedLanguage] || processName.name_en}</td>
                    <td className="align-middle">{menuItem.price}</td>
                    <td className="align-middle text-end">
                        <div className="btn-group" role="group">
                            <OrderButton menuItem={menuItem} orderCode={orderCode} />
                            <ManageMenuItemButton menuItem={menuItem} />
                        </div>
                    </td>
                </tr>
            )
        } else {
            return <tr></tr>
        }
    }

    const MenuItemList = ({ items, orderCode }) => {
        if (items) {
            return (
                <tbody>
                    {items.map((item) => (<MenuItem key={item.menu_item_code} menuItem={item} orderCode={orderCode} />))}
                </tbody>
            )
        } else {
            return <tbody></tbody>
        }
    }

    const UploadNewMenu = () => {

        const uploadMenuItems = async (newMenuItems) => {
            for (const newMenuItem of newMenuItems) {
                const existingMenuItemIndex = CURRENT_TENANT.menu.findIndex(
                    ({ name }) => name === newMenuItem.name
                );
                const menuItem = {
                    ...newMenuItem,
                    category: getProcessBySlug(newMenuItem.category, CURRENT_TENANT)?.id,
                    tenant: CURRENT_TENANT.id,
                    is_active: true,
                };

                if (existingMenuItemIndex !== -1) {
                    const updatedMenuItem = {
                        ...CURRENT_TENANT.menu[existingMenuItemIndex],
                        ...menuItem,
                    };
                    CURRENT_TENANT.menu[existingMenuItemIndex] = updatedMenuItem;

                    const menuItemId = CURRENT_TENANT.menu[existingMenuItemIndex].id;
                    await axiosPrivate.patch(
                        `${TENANT_URL}${CURRENT_TENANT.id}/menu/${menuItemId}/`,
                        JSON.stringify(updatedMenuItem)
                    );
                } else {
                    CURRENT_TENANT.menu.push(menuItem);
                    await axiosPrivate.post(
                        `${TENANT_URL}${CURRENT_TENANT.id}/menu/`,
                        JSON.stringify(menuItem)
                    );
                }
            }
            setTenants({ ...tenants, current: CURRENT_TENANT });
        };

        const handleSubmit = (e) => {
            e.preventDefault();

            let inputFile = undefined;

            // Check if user has entered the file
            if (e.target.elements.csvFile.files.length) {
                inputFile = e.target.elements.csvFile.files[0];

                // Check the file extensions, if it not
                // included in the allowed extensions
                // we show the error
                const fileExtension = inputFile?.type.split("/")[1];
                if (
                    !ALLOWED_EXTENSIONS.includes(fileExtension)
                ) {
                    // setError("Please input a csv file");
                    console.log("Please input a csv file");
                    return;
                }

                // If input type is correct set the state
                // setFile(inputFile);
            }

            // If user clicks the parse button without
            // a file we show a error
            if (!inputFile) return alert("Enter a valid file");

            // Initialize a reader which allows user
            // to read any file or blob.
            const reader = new FileReader();

            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, {
                    header: true,
                });
                const parsedData = csv?.data;
                uploadMenuItems(parsedData);
            };
            reader.readAsText(inputFile);
        }

        if (isAllowed(currentRoles, [DEFAULT_ROLES.owners])) {
            return (
                <div className="row pt-4">
                    <div className="col"></div>
                    <div className="col-auto text-end">
                        <h5 className="fw-bold">{t('Upload a new menu from CSV file')}</h5>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <input
                                    type="file"
                                    className="form-control"
                                    id="csvFile"
                                    name="csvFile" />
                                <button type="submit" className="btn btn-primary">{t('Upload')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            <ContentHeader text={t('Menu')} subtext={t('Select an item to create an order')} />
            <div className="row">
                <div className="col">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{t('Item')}</th>
                                    <th>{t('Category')}</th>
                                    <th>{t('Price')}</th>
                                    <th className="text-end">{t('Actions')}</th>
                                </tr>
                            </thead>
                            <MenuItemList items={CURRENT_TENANT.menu} orderCode={code} />
                        </table>
                        {/* {% include "partials/paginator.html" %} */}
                    </div>
                </div>
            </div>
            <UploadNewMenu />
        </>
    )
}

export default Menu