// import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import ContentHeader from './ContentHeader';


const Missing = () => {
    const { t, i18n } = useTranslation();

    return (
        <ContentHeader text={t('Oops') + '!'} subtext={t('Page Not Found')} />
        // <article style={{ padding: "100px" }}>
        //     <h1>Oops!</h1>
        //     <p>Page Not Found</p>
        //     <div className="flexGrow">
        //         <Link to="/">Visit Our Homepage</Link>
        //     </div>
        // </article>
    )
}

export default Missing
