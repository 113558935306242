import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useLogout from "../hooks/useLogout";


const Logout = () => {
    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        localStorage.removeItem('persist');
        navigate('/login');
    }
    
    useEffect(() => {
        signOut();
    }, [])

    return <></>;
}

export default Logout;