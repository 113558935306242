import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

import ContentHeader from './ContentHeader';

import useAuth from "../hooks/useAuth";
import useOrders from "../hooks/useOrders";
import useTenants from "../hooks/useTenants";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useCurrentOrderLoader } from "../hooks/useOrdersLoader";

import apiDateConverter from '../utils/apiDateConverter';
import uniqByMenuItem from '../utils/uniqByMenuItem';
import {
    getMenuItem,
    getOrderStatus,
    getOrderStatusBySlug,
} from '../utils/tenantUtils';
import { isAllowed, getCurrentRoles } from "../utils/authUtils";
import { DEFAULT_ROLES, TENANT_URL } from "../constants";

import "./ClosedOrder.css";


const ClosedOrder = ({ code }) => {
    const { t, i18n } = useTranslation();

    const { auth } = useAuth();

    const { tenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const currentRoles = getCurrentRoles(auth, CURRENT_TENANT);

    const { orders, setOrders } = useOrders();
    
    const [ currentOrder, setCurrentOrder ] = useState(orders?.find((order) => order.order_code === code));

    const loadCurrentOrder = useCurrentOrderLoader();
    
    
    useEffect(() => {
        const updateCurrentOrder = async () => {
            try {
                const updatedOrder = await loadCurrentOrder(currentOrder);
                if ( !isEqual(currentOrder, updatedOrder)) {
                    setCurrentOrder(updatedOrder);
                }
            } catch (err) {
                console.error(err);
            } 
        }
        updateCurrentOrder();
    }, [currentOrder])

    const PaidOrderButton = ({ order }) => {
        const paidOrder = async () => {
            try {
                // Paid order
                await axiosPrivate.patch(TENANT_URL + CURRENT_TENANT.id + '/order/' + order.id + '/',
                    JSON.stringify({
                        order_status: getOrderStatusBySlug('paid', CURRENT_TENANT).id,
                    })
                );
                const order_status = getOrderStatusBySlug('paid', CURRENT_TENANT).id;
                setCurrentOrder({ ...currentOrder, order_status });
                // setOrders([...orders.filter((order) => order.id !== currentOrder.id), currentOrder]);
                navigate('/orders');
            } catch (err) {
                console.error(err);
            }
        }
        if (isAllowed(currentRoles, [DEFAULT_ROLES.managers, DEFAULT_ROLES.owners])) {
            if (order?.order_status === getOrderStatusBySlug('closed', CURRENT_TENANT).id) {
                return (
                    <button className="btn btn-success shadow mx-2" onClick={paidOrder}>{t('Paid')}</button>
                )
            } else {
                return <></>
            }
        } else {
            return <></>
        }
    }

    const ClosedOrderItemList = ({ orderItems }) => {
        if (orderItems) {
            return (
                <>
                    {uniqByMenuItem(orderItems).map((orderItem) => (<ClosedOrderItem key={orderItem.id} orderItem={orderItem} />))}
                </>
            )
        } else {
            return <div></div>
        }
    }

    const ClosedOrderItem = ({ orderItem }) => {
        // if (currentOrder) {
            return (
                <div className="row">
                    <div className="col">
                        <p>{orderItem.quantity} x {getMenuItem(orderItem, CURRENT_TENANT)?.name}</p>
                    </div>
                    <div className="col">
                        <p className="fw-bold text-end">
                            <span>{orderItem.total} ILS</span>
                        </p>
                    </div>
                </div>
            )
        // } else {
        //     return <div></div>
        // }
    }

    const Result = ({ order }) => {
        const orderStatus = getOrderStatus(order, CURRENT_TENANT)
        return (
            <>
                <ContentHeader text={t('Order') + ": " + order.order_code} subtext={t("Status:") + orderStatus['name_' + i18n.resolvedLanguage] || orderStatus.name_en} />
                <div className="row mb-5">
                    <div className="col-md-8 col-xl-6 text-center mx-auto">
                        <div className="py-2">
                            <div className="text-start closed-order-border">
                                <div className="p-4">
                                    <h4 className="fw-bold mb-4">{t('Order') + ": " + order.order_code}</h4>
                                    <p className="fw-bold">{t('Table') + ": " + order.table}</p>
                                    <p className="fw-bold mb-0">{t('Status')}</p>
                                    <p>{orderStatus['name_' + i18n.resolvedLanguage] || orderStatus.name_en}</p>
                                    <p className="fw-bold mb-0">{t('Created')}</p>
                                    <p className="mb-0">{apiDateConverter(order.created)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="py-2">
                            <div className="text-start closed-order-border">
                                <div className="p-4">
                                    <div className="row">
                                        <div className="col">
                                            <h4 className="fw-bold mb-4">{t('Order Summary')}</h4>
                                        </div>
                                    </div>
                                    <ClosedOrderItemList orderItems={order.oder_items} />
                                    <div className="row">
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>{t('Subtotal')}</p>
                                            <p>{t('Tax (17%)')}</p>
                                        </div>
                                        <div className="col">
                                            <p className="text-end">
                                                <span>{order.subtotal} ILS</span>
                                            </p>
                                            <p className="text-end">
                                                <span>{order.tax} ILS</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>{t('Total')}</p>
                                        </div>
                                        <div className="col">
                                            <p className="fw-bold text-end">
                                                <span>{order.total} ILS</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-2">
                            <PaidOrderButton order={order} />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <Result order={currentOrder} />
    )

}

export default ClosedOrder