import useAxiosPrivate from './useAxiosPrivate';
import useOrders from "./useOrders";
import useTenants from "./useTenants";

import { TENANT_URL } from "../constants";


const useCurrentOrderLoader = () => {
    const axiosPrivate = useAxiosPrivate();

    const { tenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const { orders, setOrders } = useOrders();


    const currentOrderLoader = async (order) => {
        try { 
            const response = await axiosPrivate.get(TENANT_URL + order?.tenant + '/order/' + order?.id + '/');
            const responseData = response?.data;
            let currentOrder = await getOrderItems(responseData);
            setOrders([...orders.filter((item) => item.id !== currentOrder.id), currentOrder]);
            return currentOrder;
        } catch (err) {
            console.error(err);
        }
    }

    const getOrderItems = async (currentOrder) => {
        try {
            const response = await axiosPrivate.get(TENANT_URL + CURRENT_TENANT.id + '/order/' + currentOrder.id + '/item/');
            const responseData = response?.data;
            currentOrder.oder_items = responseData;
            return currentOrder;
        } catch (err) {
            console.error(err);
        }
    }

    return currentOrderLoader;
}

const useOrdersLoader = () => {
    const axiosPrivate = useAxiosPrivate();

    const { tenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const { setOrders } = useOrders();

    const ordersLoader = async () => {
        try {
            const response = await axiosPrivate.get(TENANT_URL + CURRENT_TENANT?.id + '/order/');
            const responseData = response?.data;
            setOrders(responseData);
            return responseData;
        } catch (err) {
            console.error(err);
        }
    }

    return ordersLoader;
}

export { useOrdersLoader, useCurrentOrderLoader }