import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";

import useAuth from '../hooks/useAuth';
import { useTenantsLoader } from "../hooks/useTenantsLoader";

import Loading from "./Loading";

const LoadTenants = () => {
    const { auth } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    const loadTenants = useTenantsLoader();


    const getTenants = async () => {
        try {
            await loadTenants();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // Get tenants from the server
        getTenants();
    }, [auth])

    return (
        <>
            {
                isLoading
                    ? <Loading />
                    : <Outlet />
            }
        </>
    )
}

export default LoadTenants