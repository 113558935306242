import useAxiosPrivate from './useAxiosPrivate';
import useTenants from "./useTenants";
import useLocalStorage from "./useLocalStorage";

import { TENANT_URL } from "../constants";


const useCurrentTenantLoader = () => {
    const axiosPrivate = useAxiosPrivate();

    const currentTenantLoader = async (tenant) => {
        const getProcesses = async (id) => {
            try {
                const response = await axiosPrivate.get(TENANT_URL + id + '/process/');
                const responseData = response?.data;
                return responseData
            } catch (err) {
                console.error(err);
            }
        }

        const getMenu = async (id) => {
            try {
                const response = await axiosPrivate.get(TENANT_URL + id + '/menu/');
                const responseData = response?.data;
                return responseData;
            } catch (err) {
                console.error(err);
            }
        }

        try {
            return {
                ...tenant,
                processes: await getProcesses(tenant?.id),
                menu: await getMenu(tenant?.id),
            }
        } catch (err) {
            console.error(err);
        }
    }

    return currentTenantLoader;
}

const useTenantsLoader = () => {
    const axiosPrivate = useAxiosPrivate();
    const loadCurrentTenant = useCurrentTenantLoader();

    const { tenants, setTenants } = useTenants();

    const [localTenantId] = useLocalStorage('tenant',  0);

    const tenantsLoader = async () => {
        try {
            // Get tenants
            const response = await axiosPrivate.get(TENANT_URL);
            const responseData = response?.data;

            if (tenants.all !== responseData || tenants?.current?.id !== localTenantId) {
                const currentTenantId = localTenantId !== 0 ? localTenantId : responseData[0].id;
                setTenants({
                    all: responseData,
                    current: await loadCurrentTenant(responseData.filter((item) => item.id === currentTenantId)[0])
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    return tenantsLoader;
}

export { useCurrentTenantLoader, useTenantsLoader }