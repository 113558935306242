import { Navigate } from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import useTenants from "../hooks/useTenants";

import { isAllowed, getCurrentRoles } from "../utils/authUtils";
import { DEFAULT_ROLES } from "../constants";


const RedirectFromRoot = () => {
  const { auth } = useAuth();
  const { tenants } = useTenants();
  const CURRENT_TENANT = tenants?.current;

  const ordersRoles = [DEFAULT_ROLES.owners, DEFAULT_ROLES.managers, DEFAULT_ROLES.waiters]
  const currentRoles = getCurrentRoles(auth, CURRENT_TENANT);
  // console.log("auth", auth);
  if (!auth.user) {
    return <Navigate to="/logout" replace />
  } else {
    if (currentRoles?.length > 0 && ordersRoles?.length > 0) {
      if (isAllowed(currentRoles, ordersRoles)) {
        return (
          <Navigate to="/orders" replace />
        )
      } else {
        for (let i = 0; i < currentRoles.length; i++) {
          return (
            <Navigate to={"/process/" + currentRoles[i]} replace />
          )
        }
      }
    }
  }
}

export default RedirectFromRoot;