import axios from '../api/axios';
import useAuth from './useAuth';

import { getAuth } from "../utils/authUtils";

const REFRESH_TOKEN_URL = '/auth/token/refresh/';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        try {
            const response = await axios.post(REFRESH_TOKEN_URL, null, {
                withCredentials: true
            });
            const responseData = response?.data;
            setAuth(await getAuth(response));
            return responseData.access;
        } catch (err) {
            console.error(err);
        }
    }
    return refresh;
};

export default useRefreshToken;
