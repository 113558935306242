import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import useAuth from "../hooks/useAuth";
import useTenants from "../hooks/useTenants";

import { isAllowed, isProhibited, getCurrentRoles  } from "../utils/authUtils";
import { DEFAULT_ROLES } from "../constants";


const NavbarNav = () => {
    const location = useLocation();
    
    const { auth } = useAuth();
    const { tenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;
    
    const { t, i18n } = useTranslation();

    const defaultNavItems = [
        {
            "name": "Menu",
            "name_en": "Menu",
            "name_he": "תפריט",
            "name_ru": "Меню",
            "slug": "menu",
        },
        {
            "name": "Orders",
            "name_en": "Orders",
            "name_he": "הזמנות",
            "name_ru": "Заказы",
            "slug": "orders",
        }
    ]

    const navItemsList = []
    if (CURRENT_TENANT) {
        const currentRoles = getCurrentRoles(auth, CURRENT_TENANT);
        if (isAllowed(currentRoles, [DEFAULT_ROLES.owners, DEFAULT_ROLES.managers, DEFAULT_ROLES.waiters])) {
            navItemsList.push(...defaultNavItems)
        }
        if (CURRENT_TENANT.processes && !isProhibited(currentRoles, [DEFAULT_ROLES.waiters])) {
            navItemsList.push(...tenants.current.processes)
        }
    }

    const NavItem = ({item}) => {
        const getClassNames = (name) => {
            let defaultClassNames = "nav-link text-capitalize";
            if (name === location.pathname.replace(/\//g, '')) {
                return defaultClassNames + " active"
            } else {
                return defaultClassNames
            }
        }
        const getLink = (item) => {
            if (defaultNavItems.find((elm) => elm.slug === item.slug)) {
                return '/' + item.slug
            } else {
                return '/process/' + item.slug
            }

        }
        return (
            <li className="nav-item">
                <Link className={getClassNames(item.slug)} to={getLink(item)}>{item['name_' + i18n.resolvedLanguage] || item.name_en}</Link>
            </li>
        )
    }
    const NavItems = ({items}) => items.map((item) => <NavItem key={item.slug} item={item} />)

    return (
        <ul className="navbar-nav">
            <NavItems items={navItemsList}/>
        </ul>
    )
}

export default NavbarNav