import { useLocation, Navigate, Outlet } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import useTenants from "../hooks/useTenants";

import { isAllowed, isProhibited, getCurrentRoles  } from "../utils/authUtils";

const RequireRoles = ({ allowedRoles, prohibitedRoles }) => {
    const { auth } = useAuth();
    
    const { tenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const location = useLocation();

    const currentRoles = getCurrentRoles(auth, CURRENT_TENANT);

    const hasRequiredRoles = allowedRoles?.length ? isAllowed(currentRoles, allowedRoles) : true;
    const hasProhibitedRoles = prohibitedRoles?.length ? isProhibited(currentRoles, prohibitedRoles) : false;

    if (hasRequiredRoles && !hasProhibitedRoles) {
        return <Outlet />
    }

    return <Navigate to="/logout" state={{ from: location }} replace />
}

export default RequireRoles;