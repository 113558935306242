import axios from '../api/axios';


const isIncluded = (userRoles, rolesList) => {
    if (userRoles?.length > 0) {
        const matches = userRoles.filter(role => rolesList?.includes(role))
        if (matches.length > 0) {
            return true;
        } else {
            return false
        }
    } else {
        return false
    }
}

const getRoles = async (accessToken) => {
    const ROLES_URL = '/auth/user/role/';
    try {
        const response = await axios.get(ROLES_URL,
            {
                headers: { 'Authorization': `Bearer ${accessToken}` },
                withCredentials: true
            }
        );
        const responseData = response?.data;
        return responseData
    } catch (err) {
        console.error(err);
    }
}

const getUser = async (accessToken) => {
    const USER_URL = '/auth/user/';
    try {
        const response = await axios.get(USER_URL,
            {
                headers: { 'Authorization': `Bearer ${accessToken}` },
                withCredentials: true
            }
        )
        const responseData = response?.data;
        return responseData;
    } catch (err) {
        console.error(err);
    }
}

const getAuth = async (authResponse) => {
    const accessToken = authResponse?.data?.access;
    // Get roles from the server
    let roles = [];
    let user = {};
    if (accessToken) {
        roles = await getRoles(accessToken)
        if (!authResponse?.data?.user) {
            user = await getUser(accessToken);
        } else {
            user = await authResponse?.data?.user;
        }
    } else {
        console.console.error("getAuth: accessToken not found");
    }

    return { user, accessToken, roles };
}

const getCurrentRoles = (auth, tenant) => {
    const roles = auth.roles;
    if (roles?.length > 0) {
        return roles.find(role => role.tenant?.id === tenant?.id)?.roles
    } else {
        return []
    }
}

export { isIncluded as isAllowed, isIncluded as isProhibited, getAuth, getCurrentRoles }