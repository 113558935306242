import useAxiosPrivate from '../hooks/useAxiosPrivate';

import useAuth from "./useAuth";
import useTenants from "../hooks/useTenants";


const LOGOUT_URL = '/auth/logout/';

const useLogout = () => {
    const axiosPrivate = useAxiosPrivate();

    const { setAuth } = useAuth();
    const { setTenants } = useTenants();

    const logout = async () => {
        setAuth({});
        setTenants({});
        try {
            await axiosPrivate.post(LOGOUT_URL)
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout