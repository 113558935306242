import { useTranslation } from 'react-i18next';

import ContentHeader from "./ContentHeader";


const Loading = () => {
    const { t } = useTranslation();

    return (
        <>
            <ContentHeader text={t('Loading') + "..."} subtext={t('Please wait')} />
            <div className="text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </>
    )
}

export default Loading