import NavbarLogo from "./NavbarLogo";
import NavbarButtons from "./NavbarButtons";
import NavbarNav from "./NavbarNav";


const Navbar = ({ brandName }) => {
    return (
        <nav id="navbar" className="navbar navbar-expand-md sticky-top bg-body py-3">
            <div className="container">
                <NavbarLogo brandName={brandName} />
                <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-1">
                    <span className="visually-hidden">Toggle navigation</span>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse text-end" id="navcol-1" style={{marginTop: "2px"}} > 
                    <NavbarNav /> 
                    <NavbarButtons/> 
                </div>
            </div>
        </nav>
    )
}

export default Navbar