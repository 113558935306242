// import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import useAuth from "../hooks/useAuth";
import useTenants from "../hooks/useTenants";
import { useCurrentTenantLoader } from "../hooks/useTenantsLoader";
import useLocalStorage from "../hooks/useLocalStorage";
// import { use } from "i18next";


const NavbarButtons = () => {
    const { auth } = useAuth();

    const navigate = useNavigate();
    const loadCurrentTenant = useCurrentTenantLoader();

    const { tenants, setTenants } = useTenants();
    const CURRENT_TENANT = tenants?.current;

    const { t, i18n } = useTranslation();
    const [localLang, setLocalLang] = useLocalStorage('lang', 'en');
    // const [localTenantId, setLocalTenantId] = useLocalStorage('tenant', CURRENT_TENANT ? CURRENT_TENANT?.id : tenants[0]?.id);
    const [localTenantId, setLocalTenantId] = useLocalStorage('tenant',  0);


    const TenantsList = ({ tenants }) => {
        const selectDropdownTenant = async (selectedTenant) => {
            setTenants({ ...tenants, current: await loadCurrentTenant(selectedTenant) });
            setLocalTenantId(selectedTenant.id);
            navigate('/');
        }

        const listItems = [];
        if (tenants?.all) {
            for (let i = 0; i < tenants.all.length; i++) {
                if (tenants?.all[i]?.name !== CURRENT_TENANT.name) {
                    listItems.push(<button className="dropdown-item" key={tenants?.all[i]?.name} onClick={() => selectDropdownTenant(tenants?.all[i])} >{tenants?.all[i]?.name}</button>)
                }
            }
        }
        return listItems;
    };

    const TenantsDropdown = ({ tenants }) => {
        if (CURRENT_TENANT) {
            if( tenants?.all.length > 1) {
                return (
                    <div className="dropdown">
                        <button className="btn btn-outline-primary dropdown-toggle py-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {CURRENT_TENANT.name}
                        </button>
                        <div className="dropdown-menu">
                            <TenantsList tenants={tenants} />
                        </div>
                    </div>
                )
            } else {
                // setLocalTenantId(CURRENT_TENANT.id);
                localStorage.setItem(CURRENT_TENANT.name, 'tenant')
                return <div></div>
            }
        }
    }

    const LangugeDropdown = () => {
        const lngs = {
            en: { nativeName: 'English' },
            he: { nativeName: 'עברית' },
            ru: { nativeName: 'Русский' }
        };

        const saveLanguage = (lng) => {
            i18n.changeLanguage(lng);
            setLocalLang(lng);
        }

        return (
            <div className="dropdown ps-2">
                <button className="btn btn-outline-primary dropdown-toggle py-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {lngs[i18n.resolvedLanguage].nativeName}
                </button>
                <ul className="dropdown-menu" id="language-list">
                    {Object.keys(lngs).map((lng) => (
                        i18n.resolvedLanguage !== lng
                            ? <li key={lng}> <button className="dropdown-item" onClick={() => saveLanguage(lng)}>{lngs[lng].nativeName}</button> </li>
                            : null
                    ))}
                </ul>
            </div>
        )
    }

    const LogoutButton = () => {

        if (auth?.accessToken) {
            return (
                // <button className="btn py-2 ms-2 btn-outline-primary" onClick={signOut}>{t('Logout')}</button>
                <Link to="/logout" className="btn py-2 ms-2 btn-outline-primary">{t('Logout')}</Link>
            )
        } else {
            return <div></div>
        }
    }

    return (
        <div className="ms-auto d-inline-flex">
            <TenantsDropdown tenants={tenants} />
            <LangugeDropdown />
            <LogoutButton />
        </div>
    )
}

export default NavbarButtons