import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";

import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';
import useLocalStorage from "../hooks/useLocalStorage";

import Loading from "./Loading";


const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const [persist] = useLocalStorage('persist', false);

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh(auth);
            }
            catch (err) {
                console.error(err);
            } finally {
                isMounted && setIsLoading(false);
            }
        }

        // Avoids unwanted call to verifyRefreshToken
        !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, [auth])

    return (
        <>
            {!persist
                ? <Outlet />
                : isLoading
                    ? <Loading />
                    : <Outlet />
            }
        </>
    )
}

export default PersistLogin